export const fade = {
	initial: {
		opacity: 0
	},
	animate: {
		opacity: 1
	},
	exit: {
		opacity: 0
	}
};
export const fadeAndSlide = {};
