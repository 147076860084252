export const winkels = [
	{
		id: 1,
		location: {
			lat: '52.101250',
			lng: '5.772500'
		},
		name: 'Spar',
		address: 'Dorpsstraat 4a, 6731 AT Otterlo, +31 (318 591246)',
		link: 'http://otterlo.spar.nl/',
		website: 'www.otterlo.spar.nl',
		openingTimes: [
			{ day: 'Maandag', times: '08:00 19:00' },
			{ day: 'Dinsdag', times: '08:00 19:00' },
			{ day: 'Woensdag', times: '08:00 19:00' },
			{ day: 'Donderdag', times: '08:00 19:00' },
			{ day: 'Vrijdag', times: '08:00 19:00' },
			{ day: 'Zaterdag', times: '08:00 19:00' },
			{ day: 'Zondag', times: 'Gesloten' }
		]
	},

	{
		id: 2,
		location: {
			lat: '52.128770',
			lng: '5.754380'
		},
		name: 'Supermarkt Plus Pater',
		address: 'Dorpsstraat 28, 6732 AE Harskamp, +31 (318 456435)',
		link: 'http://www.plus.nl/',
		website: 'www.plus.nl',
		openingTimes: [
			{ day: 'Maandag', times: '08:00 19:00' },
			{ day: 'Dinsdag', times: '08:00 19:00' },
			{ day: 'Woensdag', times: '08:00 19:00' },
			{ day: 'Donderdag', times: '08:00 19:00' },
			{ day: 'Vrijdag', times: '08:00 19:00' },
			{ day: 'Zaterdag', times: '08:00 19:00' },
			{ day: 'Zondag', times: 'Gesloten' }
		]
	},

	{
		id: 3,
		location: {
			lat: '52.065491',
			lng: '5.626991'
		},
		name: 'Supermarkt Albert Heijn',
		address: 'Bunschoterplen 1,EDE, +31 (318 610583)',
		link: 'http://www.ah.nl/',
		website: 'www.ah.nl',
		openingTimes: [
			{ day: 'Maandag', times: '07:00 22:00' },
			{ day: 'Dinsdag', times: '07:00 22:00' },
			{ day: 'Woensdag', times: '07:00 22:00' },
			{ day: 'Donderdag', times: '07:00 22:00' },
			{ day: 'Vrijdag', times: '07:00 22:00' },
			{ day: 'Zaterdag', times: '07:00 22:00' },
			{ day: 'Zondag', times: '12:00 - 17:00' }
		]
	},

	{
		id: 4,
		location: {
			lat: '52.050797',
			lng: '5.644071'
		},
		name: 'Supermarkt Albert Heijn',
		address: 'Parkweide 14, 6718 DJ Ede, +31 (318 610583)',
		link: 'http://www.plus.nl/',
		website: 'www.ah.nl',
		openingTimes: [
			{ day: 'Maandag', times: '07:00 22:00' },
			{ day: 'Dinsdag', times: '07:00 22:00' },
			{ day: 'Woensdag', times: '07:00 22:00' },
			{ day: 'Donderdag', times: '07:00 22:00' },
			{ day: 'Vrijdag', times: '07:00 22:00' },
			{ day: 'Zaterdag', times: '07:00 22:00' },
			{ day: 'Zondag', times: '12:00 - 17:00' }
		]
	},

	{
		id: 5,
		location: {
			lat: '52.103158',
			lng: '5.772513'
		},
		name: 'Slagerij Wilbrink',
		address: 'Harskamperweg 20, 6731 AB Otterlo, +31 (318 591209)',
		link: 'http://slagerij-wilbrink.nl/',
		website: 'slagerij-wilbrink.nl/',
		openingTimes: [
			{ day: 'Maandag', times: '08:30 - 12:30 / 13:00 17:30' },
			{ day: 'Dinsdag', times: '08:30 - 12:30 / 13:00 17:30' },
			{ day: 'Woensdag', times: '08:30 - 12:30 / 13:00 17:30' },
			{ day: 'Donderdag', times: '08:30 - 12:30 / 13:00 17:30' },
			{ day: 'Vrijdag', times: '08:30 - 12:30 / 13:00 17:30' },
			{ day: 'Zaterdag', times: '08:30 - 12:30 / 13:00 17:30' },
			{ day: 'Zondag', times: 'Gesloten' }
		]
	},

	{
		id: 6,
		location: {
			lat: '52.100636',
			lng: '5.771207'
		},
		name: 'Bakkerij Waal',
		address: 'Arnhemseweg 2, 6731 BS Otterlo, +31 (318 591543)',
		link: 'http://www.bakkerijwaal.nl/',
		website: 'www.bakkerijwaal.nl/',
		openingTimes: [
			{ day: 'Maandag', times: '08:15 - 17:30' },
			{ day: 'Dinsdag', times: '08:15 - 17:30' },
			{ day: 'Woensdag', times: '08:15 - 17:30' },
			{ day: 'Donderdag', times: '08:15 - 17:30' },
			{ day: 'Vrijdag', times: '08:15 - 17:30' },
			{ day: 'Zaterdag', times: '08:00 - 16:00' },
			{ day: 'Zondag', times: 'Gesloten' }
		]
	},
	// FINISH THIS ONE
	{
		id: 7,
		location: {
			lat: '52.098832',
			lng: '5.778795'
		},
		name: 'Vishandel Ebbers',
		address: 'Arnhemseweg 2, 6731 BS Otterlo, +31 (318 591543)',
		link: 'http://www.bakkerijwaal.nl/',
		website: 'www.bakkerijwaal.nl/',
		openingTimes: [
			{ day: 'Maandag', times: '08:15 - 17:30' },
			{ day: 'Dinsdag', times: '08:15 - 17:30' },
			{ day: 'Woensdag', times: '08:15 - 17:30' },
			{ day: 'Donderdag', times: '08:15 - 17:30' },
			{ day: 'Vrijdag', times: '08:15 - 17:30' },
			{ day: 'Zaterdag', times: '08:00 - 16:00' },
			{ day: 'Zondag', times: 'Gesloten' }
		]
	},

	{
		id: 8,
		location: {
			lat: '52.098832',
			lng: '5.778795'
		},
		name: 'Aspergeboerderij en Boerenlandwinkel',
		address: 'Apeldoornseweg 198, 6731 SC Otterlo, +31 (318 591285)',
		link: 'http://www.aspergeboerderij.nl/',
		website: 'www.aspergeboerderij.nl/',
		openingTimes: [
			{ day: 'Maandag', times: '08:00 - 18:00' },
			{ day: 'Dinsdag', times: '08:00 - 18:00' },
			{ day: 'Woensdag', times: 'Gesloten' },
			{ day: 'Donderdag', times: '08:00 - 18:00' },
			{ day: 'Vrijdag', times: '08:00 - 18:00' },
			{ day: 'Zaterdag', times: '08:00 - 17:00' },
			{ day: 'Zondag', times: 'Gesloten' }
		]
	},

	{
		id: 9,
		location: {
			lat: '52.140609',
			lng: '5.759032'
		},
		name: 'Slagerij ’t Pleintje',
		address: 'Kraatsweg 3, 6732 AL Harskamp, +31 (318 453933)',
		link: 'http://www.aspergeboerderij.nl/',
		website: 'www.aspergeboerderij.nl/',
		openingTimes: [
			{ day: 'Maandag', times: '08:00 - 18:00' },
			{ day: 'Dinsdag', times: '08:00 - 18:00' },
			{ day: 'Woensdag', times: '08:00 - 18:00' },
			{ day: 'Donderdag', times: '08:00 - 18:00' },
			{ day: 'Vrijdag', times: '08:00 - 18:00' },
			{ day: 'Zaterdag', times: '08:00 - 16:00' },
			{ day: 'Zondag', times: 'Gesloten' }
		]
	}
];
